/**
 * Created by osirvent on 09/11/2015.
 */
'use strict';

angular
    .module('annexaApp')
    .controller('AppCtrl', ['$http','$scope', '$localStorage', '$location', '$rootScope', '$anchorScroll', '$window', 'Language', 'LoginService', 'SignAppletFactory', 'AnnexaPendingCounterFactory', 'preloader', function($http, $scope, $localStorage, $location, $rootScope, $anchorScroll, $window, Language, LoginService, SignAppletFactory, AnnexaPendingCounterFactory, preloader) {
    	$rootScope.tenantImagesId = "default";
    	$http({
            method: 'GET',
            url: './external/tenant'
        }).success(function (data, status) {
            if(data && data.tenant){
            	$rootScope.tenantImagesId = data.tenant;
            }
        }).error(function (msg, code) {
        });
        $scope.imagesToLoad = [
            './assets/images/loading.gif'
        ];

        preloader.preloadImages($scope.imagesToLoad);

        //region Definicio de funcions

        $scope.isIE = function () {
            return !!navigator.userAgent.match(/MSIE/i) || !!navigator.userAgent.match(/Trident.*rv:11\./);
        }

        $scope.isSmart = function isSmart() {
            var ua = $window['navigator']['userAgent'] || $window['navigator']['vendor'] || $window['opera'];
            return (/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua);
        }

        $rootScope.$on('$stateChangeSuccess', openPage);

        $(document).on('show.bs.modal','.modal', function (event) {
            var zIndex = 1040 + (10 * $('.modal:visible').length);
            $(this).css('z-index', zIndex);
            setTimeout(function() {
                $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
            }, 0);
        });

        $(document).on('hidden.bs.modal', '.modal', function (event) {
            angular.element('body').attr("data-temp","obertAppCtrl");
            angular.element('body').removeClass('modal-open');
        });

        function openPage() {
            $location.hash('content');
            $anchorScroll();
            $location.hash('');
            $('#aside').modal('hide');
        }

        $scope.logout = function() {
            LoginService.LogOut($scope);
        }

        //endregion

        //region Definicio App
        if(!$rootScope.app){
	        $rootScope.app = new App(
	            'Annexa',
	            '1.0.0',
	            window.location.protocol + '//' + window.location.host + window.location.pathname,
	            '#0cc2aa',
	            '#a88add',
	            '#fcc100',
	            '#6887ff',
	            '#6cc788',
	            '#f77a99',
	            '#f44455',
	            '#ffffff',
	            '#f1f2f3',
	            '#2e3e4e',
	            '#2a2b3c',
	            new Setting('primary', 'accent', 'warn', false, false, false, 1, ''),
	            {},
	            $rootScope.LoggedUser,
	            {}
	        );
        }else{
        	$rootScope.app.loggeduser = $rootScope.LoggedUser;
        }



        Language.getCurrent().then(function (language) {
            $rootScope.app.language = language;
            $rootScope.app.langColumn = Language.getActiveColumn();
        });

        $rootScope.app.langColumn = Language.getActiveColumn();

        if($rootScope.app.loggeduser && $rootScope.app.loggeduser.entity) {
            $rootScope.app.entity = new Entity('id', $rootScope.app.loggeduser.entity.id, $rootScope.app.loggeduser.entity.logo,
                $rootScope.app.loggeduser.entity[Language.getActiveColumn()]);
        }

        if(!$rootScope.app.menu){
            $rootScope.app.menu = [];
        }

        var setting = $rootScope.app.name + '-Setting';
        
        if (angular.isDefined($localStorage[setting])) {
            $rootScope.app.setting = $localStorage[setting];
        } else {
            $localStorage[setting] = $rootScope.app.setting;
        }
        
        $scope.$watch('app.setting', function () {
            $localStorage[setting] = $rootScope.app.setting;
        }, true);

        $scope.$watch(function(){
            return $('body').attr('class');
        }, function (newValue) {
            var stackModal = 0;
            var callModifyClass = false;

            var modalEntity = angular.element("annexa-entity[entity-definition='modalObj']:visible");
            if(modalEntity && modalEntity.length) {
                stackModal = stackModal + modalEntity.length;
            }

            var modalAdvanced = angular.element("annexa-advanced-search-modal[modal-object='modalObj']:visible");
            if(modalAdvanced && modalAdvanced.length) {
                stackModal = stackModal + modalAdvanced.length;
            }

            var modalFilter = angular.element("annexa-box-filter[modal-object='modalObj']:visible");
            if(modalFilter && modalFilter.length) {
                stackModal = stackModal + modalFilter.length;
            }

            var modalFormly = angular.element('annexa-formly-modal:visible');
            if(modalFormly && modalFormly.length){
            	if(!(modalFormly.scope() && modalFormly.scope().modalObj && modalFormly.scope().modalObj.extra && modalFormly.scope().modalObj.extra.closed)){
            		stackModal = stackModal + modalFormly.length;
            	}
            }
            
            var modalFormly2 = angular.element('annexa-modal:visible');
            if(modalFormly2 && modalFormly2.length){
                stackModal = stackModal + modalFormly2.length;
            }

            var modalFormly3 = angular.element('annexa-form-modal:visible');
            if(modalFormly3 && modalFormly3.length) {
                stackModal = stackModal + modalFormly3.length;
            }
            var modalFormly4 = angular.element('annexa-form-modal-object:visible');
            if(modalFormly4 && modalFormly4.length) {
                stackModal = stackModal + modalFormly4.length;
            }
            var modalFormly5 = angular.element('div[uib-modal-window="modal-window"]').length;
            if(modalFormly5 && modalFormly5.length) {
                stackModal = stackModal + modalFormly5.length;
            }
            if($('body').attr("data-temp")){
                if($('body').attr("data-temp") == "obert"){
                    if(stackModal > 1 && !newValue.includes('modal-open')) {
                        callModifyClass = true;
                        $('body').attr("data-temp","tancat");
                        $('body').addClass("modal-open");
                    }else if(stackModal <= 1 && newValue.includes('modal-open')){
                        callModifyClass = true;
                        $('body').attr("data-temp","tancat");
                        $('body').removeClass("modal-open");
                    }
                }else if($('body').attr("data-temp") == "obertAppCtrl") {
                    if (stackModal > 0 && !newValue.includes('modal-open')) {
                        callModifyClass = true;
                        $('body').attr("data-temp","tancat");
                        $('body').addClass("modal-open");
                    } else if (stackModal <= 0 && newValue.includes('modal-open')) {
                        callModifyClass = true;
                        $('body').attr("data-temp","tancat");
                        $('body').removeClass("modal-open");
                    }
                }else if($('body').attr("data-temp") == "tancat"){
                    $('body').removeAttr('data-temp');
                }
            }else if(!newValue.includes('modal-open') && stackModal > 0){
                callModifyClass = true;
                $('body').attr("data-temp","tancat");
                $('body').addClass("modal-open");
            }else if(newValue.includes('modal-open') && stackModal <= 0){
                callModifyClass = true;
                $('body').attr("data-temp","tancat");
                $('body').removeClass("modal-open");
            }
            if(!callModifyClass){
                $('body').removeAttr('data-temp');
            }
        }, true);

        setColor();
        
        function setColor() {
            $rootScope.app.setting.color = {
                primary: getColor($rootScope.app.setting.theme.primary),
                accent: getColor($rootScope.app.setting.theme.accent),
                warm: getColor($rootScope.app.setting.theme.warn)
            };
        };

        function getColor(name) {
            return $rootScope.app.color[name] ? $rootScope.app.color[name] : palette.find(name);
        }
        
        //endregion
        
    }]);