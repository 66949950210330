/**
 * Created by osirvent on 09/11/2015.
 */
'use strict';
window._arrayBufferToBase64 = function(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;

    for (var i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

angular
    .module('annexaApp', [
        'ngAnimate',
        'ngResource',
        'ngSanitize',
        'ngStorage',
        'oc.lazyLoad',
        'ui.router',
        'permission',
        'permission.ui',
        'tmh.dynamicLocale',
        'ghiscoding.validation',
        'pascalprecht.translate',
        'ngCookies',
        'angularUtils.directives.uiBreadcrumbs',
        'ui.bootstrap',
        'ui.select',
        'formly',
        'formlyBootstrap',
        'ngMessages',
        'datatables',
        'datatables.options',
        'datatables.buttons',
        'angular-cache',
        'annexa.helper',
        'annexa.localData',
        'angularModalService',
        'summernote',
        'xeditable',
        'ngFileUpload',
        'dialogs.main',
        'angular-bind-html-compile',
        'angular-sortable-view',
        'pdf',
        'treeControl',
        'infinite-scroll',
        'filearts.dragDrop',
        'mgo-angular-wizard',
        'ui.keypress',
        'ngStomp',
        'naif.base64',
        'ui.calendar',
        'ng-nestable',
        'image.preloader',
        'duScroll',
        'view.file',
        'ui.tree',
        'angular-cron-gen'
]);